<template>
    <b-card no-body class="p-1">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <b-row>
                <b-col cols="12" md="6">
                    <AccountLevelsTable :items="items" :columns="columns"/>
                </b-col>
                <b-col cols="12" md="6">
                    <AccountLevelsTable :items="items1" :columns="columns"/>
                </b-col>
            </b-row>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BCard,
        BOverlay,
        BRow,
        BCol,
        // BButton,
    } from 'bootstrap-vue'
    import AccountLevelsTable from "@/views/AccountLevels/AccountLevelsTable";

    export default {
        name: "AccountLevels",
        components: {
            AccountLevelsTable,
            BCard,
            BOverlay,
            BRow,
            BCol,
        },
        data: () => ({
            dir: false,
            loading: false,
            coins: [],
            items: [],
            items1: [],
            data: {},
            edit: false,
            type: false,
            columns: [
                {
                    label: 'نام سطح',
                    key: 'userAccountLevel',
                    sortable: false,
                },
                {
                    label: 'کارمزد تومانی',
                    key: 'wagePercent',
                    sortable: false,
                },
                {
                    label: 'کارمزد تتری',
                    key: 'wagePercentTether',
                    sortable: false,
                },
                {
                    label: 'مقدار تا سطح بعد',
                    key: 'tradeAmount',
                    sortable: false,
                },
            ],
        }),
        methods: {
            async getData() {
                this.state.loading = true

                const res = await this.$axios.get('/users/account-levels')

                this.items = res.data.slice(0, Math.ceil(res.data.length / 2))
                this.items1 = res.data.slice(Math.ceil(res.data.length / 2))
            },
        },
        async created() {
            await this.getData()
        }
    }
</script>

<style scoped>

</style>