<template>
    <b-table
            ref="refUserListTable"
            :items="items"
            :small="true"
            responsive
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="اطلاعاتی یافت نشد"
            class="text-nowrap"
            style="min-height : 235px"
    >

        <template #cell(wagePercent)="{item}">
            <AccountLevelTableField v-if="!['SUPERVISOR'].includes(item.userAccountLevel)" :item="item" target="wagePercent" label="کارمزد تومانی"/>
            <span v-else>0</span>
        </template>
        <template #cell(wagePercentTether)="{item}">
            <AccountLevelTableField v-if="!['SUPERVISOR'].includes(item.userAccountLevel)" :item="item" target="wagePercentTether" label="کارمزد تتری"/>
            <span v-else>0</span>
        </template>
        <template #cell(tradeAmount)="{item}">
            <AccountLevelTableField v-if="!['SUPERVISOR','PLATINUM'].includes(item.userAccountLevel)" :item="item" target="tradeAmount" label="مقدار تا سطح بعد"/>
            <span v-else>∞</span>
        </template>

    </b-table>
</template>

<script>
    import {
        BTable
    } from "bootstrap-vue";
    import AccountLevelTableField from "@/views/AccountLevels/AccountLevelTableField";

    export default {
        name: "AccountLevelsTable",
        props:['items','columns'],
        components: {
            AccountLevelTableField,
            BTable,
        },
        data(){
            return{
                dir: false,
                loading: false,
                edit: false,
                type: false
            }
        },
        methods:{
        }
    }
</script>

<style scoped>

</style>